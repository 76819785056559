import { Tooltip } from 'react-tooltip';
import { SocialIcons } from '../social-icons';
import styles from './nav-menu.module.scss';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  ForwardRefExoticComponent,
  MutableRefObject,
  Ref,
  RefObject,
  forwardRef,
} from 'react';
import { useUser } from '@auth0/nextjs-auth0/client';

interface INavMenuProps {
  className?: string;
  isOpen?: boolean;
  ref?: Ref<HTMLDivElement>;
}

export const NavMenu: React.FC<INavMenuProps> = forwardRef<
  HTMLDivElement,
  INavMenuProps
>(function NavMenu({ className, isOpen }, ref) {
  const userInfo = useUser();

  const isUserAuthenticated = Boolean(userInfo.user);
  const router = useRouter();
  return (
    <div className={`${styles.container} ${className}`} ref={ref}>
      <Tooltip
        openOnClick
        clickable
        isOpen={isOpen}
        id="nav-menu"
        style={{
          backgroundColor: 'transparent',
          marginRight: '1rem',
        }}
        render={({ content, activeAnchor }) => (
          <div className={styles.contentContainer}>
            <ul className={styles.links}>
              <li className={styles.item}>
                {isUserAuthenticated ? (
                  <Link href={`/profile?returnTo=${router.asPath}`}>
                    {' '}
                    Profile{' '}
                  </Link>
                ) : null}
              </li>
              <li className={styles.item}>
                <Link href="https://www.ollie.ai/privacy-policy"> Privacy Policy </Link>
              </li>
              <li className={styles.item}>
                <Link href="https://www.ollie.ai/cookie-policy"> Cookie Policy </Link>
              </li>
              <li className={styles.item}>
                <Link href="https://www.ollie.ai/terms-of-service"> Terms of Service </Link>
              </li>
            </ul>

            <div className={styles.actions}>
              {isUserAuthenticated ? (
                <div className={`${styles.signOut} ${styles.item}`}>
                  <Link href="/api/auth/logout">Sign out</Link>
                </div>
              ) : null}

              <SocialIcons className={styles.socialIcons}></SocialIcons>
            </div>
          </div>
        )}
      />
    </div>
  );
});
