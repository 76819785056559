import { useUser } from '@auth0/nextjs-auth0/client';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { AiOutlineArrowLeft, AiOutlinePlus } from 'react-icons/ai';
import { HiOutlineBookmark } from 'react-icons/hi';
import { FiEdit3, FiMoreHorizontal } from 'react-icons/fi';

import { useInjectable, useSubscription } from '@/src/services';
import {
  ISessionsService,
  SessionsServiceContainerType,
} from '@/src/services/sessions';
import {
  IStorageService,
  KnownStorageKeys,
  StorageServiceContainerType,
} from '@/src/services/storage';
import { useConfirmModal, useModal } from '@/src/services/modal';
import { SignUpModal } from '@/src/components/modals/sign-up-modal/sign-up-modal.component';
import { PromptEditorModal } from '@/src/components/modals/prompt-editor-modal/prompt-editor-modal.component';

import LogoIcon from '@/public/assets/logo-black.svg';
import ProfileIcon from '@/public/assets/profile.svg';
import AiAvatarPure from '@/public/assets/ai-avatar-pure-black.svg';

import { EditableItem } from '../editable-item/editable-item.component';
import { SignInIcon } from '../../icons/sign-in.icon.component';
import { NavMenu } from '../nav-menu/nav-menu.component';
import styles from './side-navigation.module.scss';
import { finalize, noop } from 'rxjs';

export interface SideNavigationProps {
  homePath?: string;
  className?: string;
}

export const SideNavigation: React.FC<SideNavigationProps> = ({
  homePath = '/',
  className,
}) => {
  const router = useRouter();
  const userInfo = useUser();

  const { sessions$, getSessions, deleteSession, updateSession } =
    useInjectable<ISessionsService>(SessionsServiceContainerType);
  const { retrieve, store } = useInjectable<IStorageService>(
    StorageServiceContainerType,
  );

  const [isClient, setIsClient] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sessions = useSubscription(sessions$);

  const sessionId = Array.isArray(router.query.id)
    ? router.query.id[0]
    : router.query.id;

  const isCollapsedState = retrieve(
    KnownStorageKeys.SideNavigationCollapsedState,
  );

  const [isCollapsed, setIsCollapsed] = useState(isCollapsedState === 'true');

  const isUserAuthenticated = Boolean(userInfo.user);
  const isConfabulationUser = Boolean(
    String(userInfo.user?.email).endsWith('@confabulation.ai'),
  );

  const onSignUp = () => {
    open(`/api/auth/login?returnTo=${router.asPath}`, '_self');
  };

  const { open: openRemovingConfirm, close: closeRemovingConfirm } =
    useConfirmModal();

  const { open: openPromptEditor, close: closePromptEditor } = useModal(
    (props) => (
      <PromptEditorModal sessionId={sessionId} onClose={closePromptEditor} />
    ),
  );

  const { open: openSignUp, close: closeSignUp } = useModal((props) => (
    <SignUpModal
      onSignUp={onSignUp}
      onClose={() => {
        closeSignUp();
        open(homePath, '_self');
      }}
    />
  ));

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    store(KnownStorageKeys.SideNavigationCollapsedState, String(isCollapsed));
  }, [isCollapsed, store]);

  useEffect(() => {
    if (isUserAuthenticated) {
      setIsLoading(true);
      getSessions()
        .pipe(finalize(() => setIsLoading(false)))
        .subscribe(noop);
    }
  }, [getSessions, isUserAuthenticated]);

  return (
    <>
      {isClient ? (
        <nav
          className={`${className || ''} ${styles.container} ${
            isCollapsed ? styles.isCollapsedContainer : ''
          }`}
        >
          <div className={styles.contentContainer}>
            <div className={styles.wrapper}>
              <div
                className={styles.collapsable}
                onClick={() => setIsCollapsed((value) => !value)}
              >
                <AiOutlineArrowLeft
                  className={`${styles.icon} ${
                    isCollapsed ? styles.isCollapsed : ''
                  }`}
                ></AiOutlineArrowLeft>
              </div>

              {isCollapsed ? (
                <div className={styles.collapsedLogoContainer}>
                  <Link
                    href={'https://www.ollie.ai'}
                    onClick={(e) => {
                      e.preventDefault();

                      if (!isUserAuthenticated && sessionId) {
                        openSignUp({
                          bodyClassName: 'signUpModalBody',
                          contentClassName: 'signUpModalContent',
                        });
                      } else {
                        open('https://www.ollie.ai', '_self');
                      }
                    }}
                  >
                    <Image
                      className={styles.logoImage}
                      alt="Ollie"
                      src={AiAvatarPure}
                      quality={100}
                    />
                  </Link>
                </div>
              ) : (
                <div className={styles.logoContainer}>
                  <Link
                    href={'https://www.ollie.ai'}
                    onClick={(e) => {
                      e.preventDefault();

                      if (!isUserAuthenticated && sessionId) {
                        openSignUp({
                          bodyClassName: 'signUpModalBody',
                          contentClassName: 'signUpModalContent',
                        });
                      } else {
                        open('https://www.ollie.ai', '_self');
                      }
                    }}
                  >
                    <Image
                      className={styles.logo}
                      src={LogoIcon}
                      quality={100}
                      alt="Ollie"
                    />
                  </Link>
                </div>
              )}

              <h3 className={`${styles.title}`}>Saved Gift Searches</h3>
              {!isUserAuthenticated ? (
                <div className={styles.signInMsg}>
                  You don’t have any saved searches.{' '}
                  <Link
                    href={`/api/auth/login?returnTo=${router.asPath}`}
                    className={styles.signInMsgLink}
                  >
                    Sign in
                  </Link>{' '}
                  so you can save your searches and pick up where you left off!
                </div>
              ) : null}
              {router.route !== '/' ? (
                <Link
                  href={homePath}
                  className={styles.newSearchLink}
                  onClick={(e) => {
                    e.preventDefault();

                    if (!isUserAuthenticated) {
                      openSignUp({
                        bodyClassName: 'signUpModalBody',
                        contentClassName: 'signUpModalContent',
                      });
                    } else {
                      open(homePath, '_self');
                    }
                  }}
                >
                  <button className={`${styles.newSearch}`}>
                    <AiOutlinePlus className={styles.addIcon}></AiOutlinePlus>
                    <span>New search</span>
                  </button>
                </Link>
              ) : null}

              <div className={styles.listWrapper}>
                <ul className={styles.list}>
                  {isUserAuthenticated
                    ? isLoading
                      ? [null, null, null].map((thread, index) => (
                          <EditableItem
                            isSkeleton
                            className={styles.editableItem}
                            key={index}
                          ></EditableItem>
                        ))
                      : (sessions || []).map((thread) => (
                          <EditableItem
                            className={styles.editableItem}
                            key={thread.id}
                            id={thread.id}
                            active={sessionId === thread.id}
                            value={thread.name || 'Unnamed session'}
                            onSave={(value) => {
                              updateSession(thread.id, value)
                                .subscribe(noop);
                            }}
                            onRemove={() =>
                              openRemovingConfirm({
                                confirmText: 'Delete session',
                                subMessage: 'This cannot be undone',
                                cancelText: 'Keep it',
                                message:
                                  'Are you sure you want to permanently delete this search?',
                                onConfirm: () => {
                                  deleteSession(thread.id)
                                    .subscribe(noop);
                                  closeRemovingConfirm();

                                  if (sessionId === thread.id) {
                                    open(homePath, '_self');
                                  }
                                },
                              })
                            }
                          ></EditableItem>
                        ))
                    : null}
                </ul>
              </div>
              <Link
                className={styles.menuItem}
                href={`/wishlists?returnTo=${router.asPath}`}
              >
                <button className={styles.menuButton}>
                  <HiOutlineBookmark
                    className={styles.menuIcon}
                  ></HiOutlineBookmark>
                  <span className={`${styles.menuItemName}`}>
                    Saved products
                  </span>
                </button>
              </Link>

              {isConfabulationUser ? (
                <div className={styles.menuItem}>
                  <button
                    className={`${styles.menuButton}`}
                    onClick={() => {
                      openPromptEditor({
                        className: styles.modalContainer,
                        bodyClassName: styles.modalBodyContainer,
                      });
                    }}
                  >
                    <FiEdit3 className={styles.menuIcon}></FiEdit3>
                    <span className={`${styles.menuItemName}`}>
                      Prompt editor
                    </span>
                  </button>
                </div>
              ) : null}

              {!isUserAuthenticated ? (
                <div className={styles.user}>
                  <Link
                    href={`/api/auth/login?returnTo=${router.asPath}`}
                    className={styles.loginLink}
                  >
                    {isCollapsed ? (
                      <SignInIcon></SignInIcon>
                    ) : (
                      <>
                        <button className={`primary ${styles.loginButton}`}>
                          Sign in
                        </button>
                      </>
                    )}
                  </Link>
                  {isCollapsed ? null : (
                    <span>
                      <FiMoreHorizontal
                        className={styles.moreIcon}
                        data-tooltip-id="nav-menu"
                      ></FiMoreHorizontal>
                      <NavMenu></NavMenu>
                    </span>
                  )}
                </div>
              ) : (
                <div className={styles.user}>
                  <Link
                    className={styles.link}
                    href={`/profile?returnTo=${router.asPath}`}
                  >
                    <button
                      className={`primary ${styles.userPreview} ${
                        userInfo.user?.picture ? styles.userPreviewSocial : ''
                      }`}
                      data-tooltip-id={isCollapsed ? 'nav-menu' : ''}
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <Image
                        src={userInfo.user?.picture || ProfileIcon}
                        quality={100}
                        fill
                        alt="Profile"
                      />
                    </button>
                    <span className={`${styles.name}`}>
                      {userInfo.user?.name}
                    </span>
                  </Link>
                  <span>
                    <FiMoreHorizontal
                      className={styles.moreIcon}
                      data-tooltip-id="nav-menu"
                    ></FiMoreHorizontal>
                    <NavMenu></NavMenu>
                  </span>
                </div>
              )}
            </div>
          </div>
        </nav>
      ) : null}
    </>
  );
};
